import Footer from "../../elements/Footer/Footer";
import {useTranslation} from 'react-i18next';
import ShopSection from "./ShopSection";
import React, {useEffect, useState} from "react";
import {StyledShopPage, StyledBalanceDisplay} from "./styles";
import {axiosInstance} from "../../api";
import Loader from "../../elements/Loader/Loader";
import confetti from "canvas-confetti";

const Market = () => {
    const {t} = useTranslation();  // Инициализируем хук для переводов

    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);  // Состояние для загрузки
    const [energyRefill, setEnergyRefill] = useState([]);
    const [maxEnergy, setMaxEnergy] = useState([]);
    const [passiveBonus, setPassiveBonus] = useState([]);


    const _confirmBuy = (item) => {
        let items = [];
        switch (item.type) {
            case 'REFILL_ENERGY':
                items = energyRefill;
                break;
            case 'ENERGY':
                items = maxEnergy;
                break;
            case 'PASSIVE_BONUS':
                items = passiveBonus
        }
        const product_id = item.product_id;

        let blocked = false;

        const new_items = items.map((item) => {
            if (item.product_id === product_id) {
                blocked = true;
            }
            return {
                ...item,
                isActive: item.product_id === product_id,
                isBlocked: blocked
            }
        });

        switch (item.type) {
            case 'REFILL_ENERGY':
                setEnergyRefill([...new_items]);
                break;
            case 'ENERGY':
                setMaxEnergy([...new_items]);
                break;
            case 'PASSIVE_BONUS':
                setPassiveBonus([...new_items]);
        }

        confetti({
            particleCount: 150,
            spread: 70,
            origin: {y: 0.9},
            shapes: ['circle'],
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
        });
    }

    const buyItem = (type, product_id) => {

        let items = [];
        switch (type) {
            case 'REFILL_ENERGY':
                items = energyRefill;
                break;
            case 'ENERGY':
                items = maxEnergy;
                break;
            case 'PASSIVE_BONUS':
                items = passiveBonus
        }


        for (let i in items) {
            let item = items[i];
            if (item.product_id === product_id) {
                if (item.isBlocked) {
                    alert(t('item_already_purchased'));
                    return;
                } else if (balance < item.price) {
                    alert(t('not_enough_funds'));
                    return;
                }

                if (!item.isDefault) {
                    axiosInstance.get(`/shop/invoice-link?product_id=${product_id}`).then(response => {
                        const link = response.data.invoice_link
                        window.Telegram.WebApp.openInvoice(link, (status) => {
                            if (status === 'paid') {
                                _confirmBuy(item)
                            }
                        })
                    })
                    setBalance(balance - item.price)
                } else if (window.confirm(t('confirm_purchase'))) {
                    axiosInstance.post('/shop/subscribe', {product_id: product_id}).then(response => {
                        setBalance(balance - item.price)
                        _confirmBuy(item)
                    }).catch((error) => {
                        alert(t('purchase_error'))
                    })
                }
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        axiosInstance.get('/shop/subscriptions').then((response) => {
            setBalance(response.data.balance);
            response.data.subscriptions.forEach((subscription) => {
                if (subscription.type === 'REFILL_ENERGY') {
                    let rBlocked = false;
                    setEnergyRefill(subscription.products.toSorted((a, b) => b.level - a.level).map((item) => {
                        if (item.connected) {
                            rBlocked = true;
                        }
                        return {
                            key: item.id,
                            product_id: item.id,
                            value: item.amount,
                            price: item.price,
                            isDefault: item.is_pay_by_coins,
                            isActive: item.connected,
                            isBlocked: rBlocked,
                            type: 'REFILL_ENERGY'
                        }
                    }));
                } else if (subscription.type === 'ENERGY') {
                    let eBlocked = false;
                    setMaxEnergy(subscription.products.toSorted((a, b) => b.level - a.level).map((item) => {
                        if (item.connected) {
                            eBlocked = true;
                        }
                        return {
                            key: item.id,
                            product_id: item.id,
                            value: item.amount,
                            price: item.price,
                            isDefault: item.is_pay_by_coins,
                            isActive: item.connected,
                            isBlocked: eBlocked,
                            type: 'ENERGY'
                        }
                    }));
                } else if (subscription.type === 'PASSIVE_BONUS') {
                    let eBlocked = false;
                    setPassiveBonus(subscription.products.toSorted((a, b) => b.level - a.level).map((item) => {
                        if (item.connected) {
                            eBlocked = true;
                        }
                        return {
                            key: item.id,
                            product_id: item.id,
                            value: item.amount,
                            price: item.price,
                            isDefault: item.is_pay_by_coins,
                            isActive: item.connected,
                            isBlocked: eBlocked,
                            type: 'PASSIVE_BONUS'
                        }
                    }));
                }
            })
        }).finally(() => setLoading(false));
    }, []);

    return (
        <>
            {loading ? (
                <Loader/>
            ) : (
                <StyledShopPage>
                    <StyledBalanceDisplay>
                        <img className="balance-icon"
                             src="/images/shop_balance_logo.png"
                             alt=""/>
                        <div className="balance-info">
                            <p className="balance-label">{t('your_balance')}</p>
                            <div className="balance-amount">
                                <img className="currency-icon"
                                     src="/images/emblem.svg"
                                     alt=""/>
                                <span className="amount">{balance}</span>
                            </div>
                        </div>
                    </StyledBalanceDisplay>
                    <ShopSection
                        title={t('energy_refill')}
                        icon="/images/shop_energy_refill_item.png"
                        unit={t('energy_per_minute')}
                        items={energyRefill}
                        buyItem={buyItem}
                    />
                    <ShopSection
                        title={t('max_energy')}
                        icon="/images/shop_max_energy_item.png"
                        unit={t('max_energy_unit')}
                        items={maxEnergy}
                        buyItem={buyItem}
                    />
                    <ShopSection
                        title={t('shop_passive_bonus')}
                        icon="/images/shop_passive_bonus_item.png"
                        unit={t('passive_bonus_unit')}
                        items={passiveBonus}
                        buyItem={buyItem}
                    />
                </StyledShopPage>
            )}
            <Footer/>
        </>
    )
}

export default Market;
