// Loader.js
import React from 'react';
import {StyledLoader} from "./styles"; // импортируем стили для анимации

const Loader = () => {
    return (
        <StyledLoader className="loader">
            <div className="spinner"></div>
        </StyledLoader>
    );
};

export default Loader;