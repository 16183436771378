import React, {useEffect, useRef, useState} from 'react';
import {
    CoinImage,
    EnergyCounter,
    FarmContainer,
    FloatingText,
    PopupButton,
    PopupContainer,
    PopupMessage,
    ProgressBar,
    Text1,
    Text2,
    TimerContainer
} from './styles';
import {axiosInstance} from "../../api";
import Footer from "../../elements/Footer/Footer";
import {useNavigate} from "react-router-dom";
import confetti from 'canvas-confetti';
import {CountUp} from 'countup.js';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import LanguageSwitcher from "../../elements/LanguageSwitcher/LanguageSwitcher";
import i18n from "i18next";

export default function Farm() {
    const {t} = useTranslation();
    const [balance, setBalance] = useState(0);
    const [energyLeft, setEnergyLeft] = useState(100);
    const [maxEnergy, setMaxEnergy] = useState(100);
    const [showPopup, setShowPopup] = useState(false);
    const [floatingTexts, setFloatingTexts] = useState([]);
    const clickQueue = useRef([]);
    const navigate = useNavigate();
    const balanceRef = useRef(null);
    const countUpInstance = useRef(null);
    const [energyRefillAmount, setEnergyRefillAmount] = useState(0);

    async function send_clicks(clicks) {
        try {
            await axiosInstance.post('/clicker', {click_count: clicks},);
        } catch (e) {
            if (e.response && e.response.status === 403) {
                // Если сервер вернул ошибку 403, показываем сообщение об ошибке
                alert(t("suspected_bots"));
            } else {
                console.log(e);
            }
        }
    }

    const handleCoinClick = (e) => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
        if (energyLeft >= 1) {
            const newBalance = balance + 10;
            animateBalance(balance, newBalance);
            setBalance(newBalance);
            setEnergyLeft(energyLeft - 1);
            clickQueue.current.push(1);

            confetti({
                particleCount: 5,
                spread: 180,
                origin: {y: 0.5},
                shapes: ['circle'],
                colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
            });

            const newText = {
                id: Date.now(),
                x: Math.random() * 80 + 10,
                y: Math.random() * 80 + 10,
                size: 14 + Math.random() * 6
            };
            setFloatingTexts(prevTexts => [...prevTexts, newText]);

            setTimeout(() => {
                setFloatingTexts(prevTexts => prevTexts.filter(text => text.id !== newText.id));
            }, 1000);
        } else {
            setShowPopup(true);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const handleTasksRedirect = () => {
        navigate('/tasks')
    };

    const animateBalance = (startValue, endValue) => {
        if (countUpInstance.current) {
            countUpInstance.current.update(endValue);
        } else {
            countUpInstance.current = new CountUp(balanceRef.current, endValue, {
                startVal: startValue,
                duration: 1,
                useEasing: true,
            });
            countUpInstance.current.start();
        }
    };

    useEffect(() => {
        axiosInstance.get('/user').then(response => {
            const walletBalance = parseFloat(response.data.wallet_balance);
            animateBalance(balance, walletBalance);
            setBalance(walletBalance);
            setMaxEnergy(response.data.max_energy);
            setEnergyLeft(response.data.energy);
            setEnergyRefillAmount(response.data.energy_refill_amount);
            if (i18n.language !== response.data.language) {
                i18n.changeLanguage(response.data.language)
            }
        }).catch(error => {
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (clickQueue.current.length > 0) {
                send_clicks(clickQueue.current.length);
                clickQueue.current = [];
            }
        }, 5000);

        return () => {
            clearInterval(interval);
            if (clickQueue.current.length > 0) {
                send_clicks(clickQueue.current.length);
            }
        };
    }, []);

    useEffect(() => {
        const refillInterval = setInterval(() => {
            if (energyLeft < maxEnergy) {
                setEnergyLeft(prevEnergy => Math.min(prevEnergy + energyRefillAmount, maxEnergy));
            }
        }, 60000); // Пополнение энергии каждую минуту

        return () => clearInterval(refillInterval);
    }, [energyLeft, maxEnergy, energyRefillAmount]);

    return (
        <>
            <LanguageSwitcher/>
            <FarmContainer>
                <Timer energyLeft={energyLeft} maxEnergy={maxEnergy} energyRefillAmount={energyRefillAmount}/>
                <CoinImage
                    tabIndex={-1}
                    src="/images/clicker_coin.gif"
                    onClick={handleCoinClick}
                    onMouseDown={e => e.preventDefault()}
                    onDragStart={(e) => e.preventDefault()}
                />
                <Text1>{t('balance')}</Text1>
                <Text2 ref={balanceRef}>{balance.toFixed(0)}</Text2>
                <ProgressBar progress={(energyLeft / maxEnergy) * 100}>
                    <div>
                        <EnergyCounter>{energyLeft}/{maxEnergy}</EnergyCounter>
                    </div>
                </ProgressBar>
                {floatingTexts.map(text => (
                    <FloatingText key={text.id} x={text.x} y={text.y} size={text.size}>+10</FloatingText>
                ))}
            </FarmContainer>
            {showPopup && (
                <PopupContainer>
                    <PopupMessage>{t('no_energy_message')}</PopupMessage>
                    <PopupButton primary={true} onClick={handleTasksRedirect}>{t('go_to_tasks')}</PopupButton>
                    <PopupButton onClick={handlePopupClose}>{t('close')}</PopupButton>
                </PopupContainer>
            )}
            <Footer/>
        </>
    );
}

function Timer({energyLeft, maxEnergy, energyRefillAmount}) {
    const {t} = useTranslation();
    const [timeUntilFull, setTimeUntilFull] = useState('');

    useEffect(() => {
        const calculateTimeUntilFull = () => {
            if (energyLeft < maxEnergy) {
                const energyNeeded = maxEnergy - energyLeft;
                const minutesUntilFull = Math.ceil(energyNeeded / energyRefillAmount);
                const now = moment();
                const fullTime = moment(now).add(minutesUntilFull, 'minutes');

                const duration = moment.duration(fullTime.diff(now));
                const hours = String(duration.hours()).padStart(2, '0');
                const minutes = String(duration.minutes()).padStart(2, '0');
                setTimeUntilFull(`${hours} ${t('hours')} ${minutes} ${t('minutes')}`);
            } else {
                setTimeUntilFull('-');
            }
        };

        const intervalId = setInterval(calculateTimeUntilFull, 1000);

        return () => clearInterval(intervalId);
    }, [energyLeft, maxEnergy, energyRefillAmount, t]);

    return (
        <TimerContainer>{t('full_energy_refill')}: {timeUntilFull}</TimerContainer>
    );
}