import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Farm from './components/pages/Farm/Farm';
import Leaderboard from './components/pages/Leaderboard/Leaderboard';
import Invite from './components/pages/Invite/Invite';
import './App.css';
import DailyReward from "./components/pages/DailyReward/DailyReward";
import Background from "./components/background/Background";
import Tasks from "./components/pages/Tasks/Tasks";
import Shop from "./components/pages/Shop/Shop";

export default function App() {

    useEffect(() => {
        window.Telegram.WebApp.setHeaderColor('#000')
        window.Telegram.WebApp.setBackgroundColor('#000')
        window.Telegram.WebApp.disableVerticalSwipes()
        window.Telegram.WebApp.expand()

    }, [])

    return (
        <Router>
            <div className="App">
                <Background/>
                <Routes>
                    <Route path="/" element={<DailyReward/>}/>

                    <Route path="/farm" element={<Farm/>}/>
                    <Route path="/leaderboard" element={<Leaderboard/>}/>
                    <Route path="/invite" element={<Invite/>}/>
                    <Route path="/tasks" element={<Tasks/>}/>
                    <Route path="/shop" element={<Shop/>}/>
                </Routes>
            </div>
        </Router>
    );
}
