import styled from "styled-components";
import { Link } from 'react-router-dom';


export const DailyRewardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


export const CoinImage = styled.img`
    width: 115px;
    margin-top: 20px;   
`;


export const DailyRewardText = styled.p`
    font-family: 'GothamMedium', serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;

    margin-top: 20px;
`;

export const DailyRewardText2 = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #9E9E9E;

    margin-top: 10px;


`;

export const DailyRewardsList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 50px);
    max-width: 500px;
    margin-left: 100px;
    margin-right: 100px;
`;

export const DailyRewardItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border-bottom: 1px solid #171717;
    cursor: pointer;
`;

export const DailyRewardItemLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const DailyRewardItemLeftText1 = styled.p`
    font-family: 'GothamMedium', serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
`;

export const DailyRewardItemLeftText2 = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;

    color: #A0A0A0;


`;


export const DailyRewardItemTotal = styled.div`
    display: flex;
`;

export const DailyRewardItemRightText1 = styled.p`
    /* 130.00 */
    
    font-family: 'GothamMedium', serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: right;
    letter-spacing: 0.02em;

`;

export const DailyRewardItemRightText2 = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;

    background: ${(props)=> {
        if (props.status === 1) {
            return 'linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%)';
        } else {
            return '#5B5B5B';
        }
    }};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;


`;

export const ButtonContainer = styled.div`
    background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);
    width: 336px;
    height: 47px;
    border-radius: 50px;
    filter: drop-shadow(0px 0px 25px rgba(253, 235, 148, 0.65));
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    
    position: fixed;


    font-family: 'GothamMedium', serif;
    text-decoration: none;
    color: #000; /* Цвет текста */
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;


    &:hover {
        background: linear-gradient(250.35deg, #d79a24 -23.78%, #d79a24 -3.87%, #f5d176 37.49%, #fde88d 57.18%, #fff7c3 71.38%, #fde88d 87.01%, #d79a24 108.32%);
    }
`;



export const PassiveBonusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
`;

export const PassiveBonusText = styled.p`
    font-family: 'GothamMedium', serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color:  #F9DF7B

`;