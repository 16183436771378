import styled from "styled-components";

export const FarmContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
    position: relative;
`;

export const CoinImage = styled.img`
    width: 300px;
    cursor: pointer;
    transition: transform 0.2s;
    user-select: none; 
    -webkit-user-drag: none;
    outline: none;

    &:active {
        transform: scale(0.9);
    }
`;

export const Text1 = styled.p`
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 25px;
`;

export const Text2 = styled.p`
    font-family: 'GothamMedium', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    position: relative;
    padding-left: 30px;
    padding-right: 15px;
    margin-top: 10px;

    &::before {
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('/images/emblem.svg') no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const ProgressBar = styled.div`
    margin-top: 20px;
    width: 300px;
    height: 4px;
    border-radius: 3px;
    background-color: #515151;

    & > div {
        position: relative;
        width: ${props => props.progress > 0 ? `${props.progress}%` : '0'};
        height: 100%;
        background: linear-gradient(250.35deg, #d79a24 -23.78%, #d79a24 -3.87%, #f5d176 37.49%, #fde88d 57.18%, #fff7c3 71.38%, #fde88d 87.01%, #d79a24 108.32%);
        border-radius: 3px;
    }
`;

export const EnergyCounter = styled.p`
    position: absolute;
    left: 0;
    top: 15px;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #fff;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
`;

export const PopupContainer = styled.div`
    width: 70vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #313131;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

export const PopupMessage = styled.div`
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
`;

export const PopupButton = styled.button`
    margin: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    ${props => props.primary ?
            'background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);'
            : 'background: #626262'};
    color: black;
    border: none;
    border-radius: 5px;

    &:hover {
        background-color: #0056b3;
    }
`;

export const FloatingText = styled.div`
    position: absolute;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    font-size: ${props => props.size}px;
    color: #d79a24;
    animation: fade 1s forwards;

    @keyframes fade {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-20px);
        }
    }
`;


export const TimerContainer = styled.div`
    position: fixed;
    max-width: 280px;
    top: 20px;
    background: rgba(34, 34, 34, 0.8);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 12px;
    color: #ffffff;
    font-family: 'GothamMedium', sans-serif;
    text-align: center;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    opacity: 0.85;
    transition: background 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background: rgba(34, 34, 34, 1);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }

    z-index: 1000; /* Чтобы таймер всегда оставался на переднем плане */
`;
