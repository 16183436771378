import React from 'react';
import styled from 'styled-components';

const ShopItem = ({value, unit, price, isActive, isDefault, product_id, buyItem, type, isBlocked}) => {

    const icon = isDefault ? '/images/emblem.svg' : '/images/emblem_usd.svg'


    const processPay = () => {
        buyItem(type, product_id)
    }

    return (
        <StyledShopItem $isActive={isActive} $isDefault={isDefault} onClick={processPay}>
            <img className="item-icon" src="/images/shop_fire.png" alt=""/>
            <div className="item-value">
                <span className="value">{value}</span>
                <br/>
                <span className="unit">{unit}</span>
            </div>
            <div className="item-price">
                {isBlocked ? <span className="price">✔️</span> :
                    <>
                        {isDefault && <img className="price-icon" src={icon} alt=""/>}
                        <span className="price">{!isDefault && '⭐️ '}{price}</span>
                    </>
                }
            </div>
        </StyledShopItem>
    );
};

export default ShopItem;

const StyledShopItem = styled.div`
    border-radius: 12px;
    background-color: #1b1a19;
    display: flex;
    min-height: 50px;
    width: 100%;
    align-items: center;
    padding: 4px 13px;
    border: ${props => props.$isActive ? '1px solid #d78541' : 'none'};
    color: ${props => props.$isDefault ? '#d5c9a3' : '#fff'};
    cursor: pointer;

    .item-icon {
        width: 30px;
        height: 42px;
        object-fit: contain;
        margin-right: 5px;
    }

    .item-value {
        flex: 1;
        text-align: left;
    }

    .value {
        font-size: 22px;
        color: ${props => props.$isDefault ? '#d5c9a3' : '#d78541'};
        font-weight: 700;
        letter-spacing: 0.4px;
    }

    .unit {
        font-size: 8px;
        color: #d5c9a4;
    }

    .item-price {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: #d78541;
        font-weight: 500;
        white-space: nowrap;
    }

    .price-icon {
        width: 17px;
        height: 18px;
        object-fit: contain;
    }
`;