import React, {useEffect, useState} from 'react';
import {LeaderboardContainer, UserList, UserItem, FilterButtons} from './styles';
import Footer from "../../elements/Footer/Footer";
import {axiosInstance} from "../../api";
import {useTranslation} from 'react-i18next';
import Loader from '../../elements/Loader/Loader';  // Импортируем лоадер

const Leaderboard = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [me, setMe] = useState(null);
    const [loading, setLoading] = useState(true);  // Состояние для загрузки

    const [intervalIndex, setIntervalIndex] = useState(0);
    const intervals = ['day', 'week', 'month', 'all_time'];

    const leadersInterval = intervals[intervalIndex];

    const intervalToStr = {
        'day': t('day'),
        'week': t('week'),
        'month': t('month'),
        'all_time': t('all_time')
    };

    useEffect(() => {
        setLoading(true);  // Показываем лоадер перед началом загрузки данных
        axiosInstance(`/user/leaderboard?interval=${leadersInterval}`)
            .then(response => {
                setUsers(response.data.data);
            })
            .finally(() => setLoading(false));  // Скрываем лоадер после завершения загрузки

        axiosInstance(`/user/referrals?interval=${leadersInterval}`)
            .then(response => {
                setMe(response.data.data);
            });
    }, [leadersInterval]);

    return (
        <>

            <>
                {loading ? (  // Если идет загрузка, показываем лоадер
                    <Loader/>
                ) : (
                    <LeaderboardContainer>
                        <FilterButtons>
                            {intervals.map((interval, index) => (
                                <button
                                    key={interval}
                                    onClick={() => setIntervalIndex(index)}
                                    style={{
                                        backgroundColor: intervalIndex === index ? '#555' : '#222',
                                        color: intervalIndex === index ? '#fff' : '#959595'
                                    }}
                                >
                                    <span>{intervalToStr[interval]}</span>
                                </button>
                            ))}
                        </FilterButtons>
                        <UserList>
                            {me && <User user={me} isMe={true}/>}
                            {users.map((user, index) => (
                                <User key={user.id} user={user} position={index + 1}/>
                            ))}
                        </UserList>
                    </LeaderboardContainer>
                )}

                <Footer/>
            </>
        </>
    );
}

function User({user, position = null, isMe = false}) {
    const {t} = useTranslation();
    return (
        <UserItem position={position} isMe={isMe}>
            <div className="leaderbord-item-left">
                <p className="leaderbord-item-number">{position && `#${position}`}</p>
                <p className="leaderbord-item-name">{user.username}</p>
            </div>
            <div className="leaderbord-item-right">
                <p className="leaderbord-item-friends">{t('invited_friends')}: {user.friends_first_level + user.friends_second_level}</p>
                <p className="leaderbord-item-balance">{parseFloat(user.wallet_balance).toFixed(0)}</p>
            </div>
        </UserItem>
    );
}

export default Leaderboard;
