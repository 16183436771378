import React, {useEffect, useState} from 'react';
import {
    InviteContainer,
    InviteBenefits,
    InviteCount,
    ButtonContainer,
    InviteCard,
    CopyLinkContainer,
    TotalFriendsStats,
    InviteFriendsListTitle, FriendList, FriendItem
} from './styles';
import Footer from "../../elements/Footer/Footer";
import {BOT_USERNAME} from "../../constants";
import {axiosInstance} from "../../api";
import { useTranslation } from 'react-i18next';

const Invite = () => {
    const { t } = useTranslation();
    const USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
    const TARGET_URL = `https://t.me/${BOT_USERNAME}?start=referid_${USER_ID}`
    const LINK = `https://t.me/share/url?url=${encodeURIComponent(TARGET_URL)}&text=${t("INVITE_REFER_TEXT")}`
    const [friends, setFriends] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)

    function shareInviteLink() {
        window.Telegram.WebApp.openTelegramLink(LINK);
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(TARGET_URL)
            .then(() => {
                alert(t('invite_copied'));
            })
            .catch(err => {
                alert(t('copy_error'));
            });
    };

    useEffect(() => {
        axiosInstance('/user/friends-stats').then(response => {
            setFriends(response.data.data.referrals)
            setTotalAmount(response.data.data.total_received_from_referrals)
            console.log(response.data.data)
        })
    }, []);

    return (
        <>
            <InviteContainer>
                <InviteCard onClick={shareInviteLink}>
                    <img src={'/images/invite_card_brand.svg'} alt="invite_card_brand"/>
                    <div>
                        <img src="/images/invite_icon.svg" alt="invite"/>
                    </div>
                </InviteCard>

                <InviteBenefits>
                    <p className="invite-benefits-title">{t('invite_friends_and_earn')}</p>
                    <div className="invite-benefits">
                        <div className="invite-benefits-block">
                            <p className="invite-percent">25%</p>
                            <p className="invite-percent-description">{t('from_earnings')}</p>
                        </div>
                        <div className="invite-benefits-block">
                            <p className="invite-percent">15%</p>
                            <p className="invite-percent-description">{t('from_friends_earnings')}</p>
                        </div>
                    </div>
                </InviteBenefits>
                <ButtonContainer onClick={shareInviteLink}>{t('invite_friends')}</ButtonContainer>
                <CopyLinkContainer onClick={handleCopy}>
                    <p>{TARGET_URL}</p>
                    <div>
                        <img src="/images/copy_icon.svg" alt="copy_icon"/>
                    </div>
                </CopyLinkContainer>
                <TotalFriendsStats>
                    <p className="title">{t('total_income')}</p>
                    <div className="total-amount">
                        <img src="/images/emblem.svg" alt="emblem"/>
                        <p>{totalAmount}</p>
                    </div>
                    <div className="total-block">
                        <p className="total-block_text">
                            <img src="/images/friends_icon.svg" alt="friends_icon"/>
                            <p>{t('total_invited_friends')}: {friends.length}</p>
                        </p>
                    </div>

                </TotalFriendsStats>
                <InviteFriendsListTitle>{t('invited_friends')}</InviteFriendsListTitle>
                <FriendList>
                    {friends.map((user, index) => (
                        <Friend user={user} position={index + 1} />
                    ))}
                </FriendList>

            </InviteContainer>
            <Footer/>
        </>

    );
}


function Friend({user, position = null, isMe = false}) {
    return (
        <FriendItem position={position} isMe={isMe}>
            <div className="leaderbord-item-left">
                <p className="leaderbord-item-number">{position && `#${position}`}</p>
                <p className="leaderbord-item-name">{user.username}</p>
            </div>
            <div className="leaderbord-item-right">
                <p className="leaderbord-item-balance">{parseFloat(user.referral_amount).toFixed(1)}</p>
            </div>
        </FriendItem>
    );
}

export default Invite;
