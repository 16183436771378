import React from 'react';
import styled from 'styled-components';
import ShopItem from './ShopItem';
const ShopSection = ({ title, icon, unit, items, buyItem }) => {
  return (
    <StyledShopSection>
      <header className="section-header">
        <img className="section-icon" src={icon} alt="" />
        <h2 className="section-title">{title}</h2>
      </header>
      <div className="items-container">
        {items.map((item, index) => (
          <ShopItem key={index} unit={unit} buyItem={buyItem} {...item}/>
        ))}
      </div>
    </StyledShopSection>
  );
};

export default ShopSection;

const StyledShopSection = styled.section`
  border-radius: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #575757;
  margin-top: 20px;

  .section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .section-icon {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }

  .section-title {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;