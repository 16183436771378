import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    LanguageSwitcherContainer, LanguageButton, LanguageDropdown, LanguageOption
} from './styles';
import {axiosInstance} from "../../api";

export default function LanguageSwitcher() {
    const {i18n} = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setIsDropdownOpen(false);
        axiosInstance.post('user/set_language/', {language: language})
    };

    const handleLanguageClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <LanguageSwitcherContainer>
            <LanguageButton onClick={handleLanguageClick}>
                {i18n.language.toUpperCase()}
            </LanguageButton>
            {isDropdownOpen && (<LanguageDropdown>
                <LanguageOption onClick={() => changeLanguage('en')}>EN</LanguageOption>
                <LanguageOption onClick={() => changeLanguage('uk')}>UK</LanguageOption>
            </LanguageDropdown>)}
        </LanguageSwitcherContainer>);
}
