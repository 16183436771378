import styled from "styled-components";


export const StyledShopPage = styled.main`
    display: flex;
    max-width: 480px;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
    padding: 30px 29px;
`;

export const StyledBalanceDisplay = styled.section`
  border-radius: 12px;
  background-color: #1b1a19;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 14px 57px;

  .balance-icon {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 10px;
  }

  .balance-info {
    display: flex;
    flex-direction: column;
  }

  .balance-label {
    color: #9e9e9e;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .balance-amount {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
  }

  .currency-icon {
    width: 25px;
    height: 24px;
    object-fit: contain;
    margin-right: 5px;
  }

  .amount {
    white-space: nowrap;
  }
`;