import React from 'react';
import {FooterContainer, FooterWrapper, NavItem} from './styles';
import {ClickerIcon, InvitesIcon, LeaderBordIcon, MarketIcon, TasksIcon} from './icons';
import {useTranslation} from 'react-i18next';

export default function Footer() {

    const {t} = useTranslation();

    return (
        <FooterWrapper>
            <FooterContainer>
                <FooterButton path="/farm" name={t('farm')} IconComponent={ClickerIcon}/>
                <FooterButton path="/Tasks" name={t('tasks')} IconComponent={TasksIcon}/>
                <FooterButton path="/leaderboard" name={t('leaderboard')} IconComponent={LeaderBordIcon}/>
                <FooterButton path="/shop" name={t('market')} IconComponent={MarketIcon}/>
                <FooterButton path="/invite" name={t('friends')} IconComponent={InvitesIcon}/>
            </FooterContainer>
        </FooterWrapper>
    );
}


const FooterButton = ({path, name, IconComponent}) => {

    const vibrate = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    };

    return (
        <NavItem onClick={vibrate} to={path} exact activeClassName="active">
            {({isActive}) => (
                <>
                    <IconComponent color={isActive ? '#B57E10' : '#B0B0B0'}/>
                    {name}
                </>
            )}
        </NavItem>
    )
};