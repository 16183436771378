import {
    ImgContainer,
    InviteLinkText,
    IsDoneIMG,
    LinkToInvite,
    ModalContainer,
    ModalContent,
    ModalOverlay,
    OfficialButton,
    OfficialButtonContainer,
    TaskBrandLogo,
    TaskContainer,
    TaskLimit,
    TaskList,
    TaskProgress,
    TasksPageContainer,
    TasksTitle
} from "./styles";
import React, {useEffect, useState} from "react";
import Footer from "../../elements/Footer/Footer";
import {axiosInstance} from "../../api";
import confetti from "canvas-confetti";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {SUPPORT_BOT_USERNAME} from "../../constants";
import {useTranslation} from 'react-i18next';
import Loader from "../../elements/Loader/Loader";


export default function Tasks() {
    const {t} = useTranslation();
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);  // Состояние для загрузки


    useEffect(() => {
        setLoading(true);  // Показываем лоадер перед началом загрузки данных
        axiosInstance.get('/achievements/user').then(response => {
            setTasks(response.data.data);
        }).finally(() => setLoading(false));
    }, []);

    const vibrate = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
    }

    const handleTaskClick = (tasks) => {
        setSelectedTask(tasks);
        setShowModal(true);
        vibrate();
    }

    const closeModal = () => {
        setShowModal(false);
        setSelectedTask(null);
    }
    const updateTask = (updatedTask) => {
        setTasks(prevTasks =>
            prevTasks.map(task =>
                task.achievement_id === updatedTask.achievement_id
                    ? {
                        ...task,
                        achievements: task.achievements.map(achievement =>
                            achievement.user_achievement_id === updatedTask.user_achievement_id
                                ? {...achievement, reward_received: true}
                                : achievement
                        )
                    }
                    : task
            )
        );
    }

    const openOfficialChat = () => {
        window.Telegram.WebApp.openTelegramLink(t('OFFICIAL_GROUP_LINK'));
        vibrate();
    }

    const openOfficialChannel = () => {
        window.Telegram.WebApp.openTelegramLink(t('OFFICIAL_CHANEL_LINK'));
        vibrate();
    }
    const openSupport = () => {
        window.Telegram.WebApp.openTelegramLink(`https://t.me/${SUPPORT_BOT_USERNAME}`);
        vibrate();
    }

    return (
        <>{loading ? (  // Если идет загрузка, показываем лоадер
            <Loader/>
        ) : (
            <TasksPageContainer>
                <TasksTitle>{t('direct_pay_tasks')}</TasksTitle>
                <LinkToInvite to={"/invite"} onClick={vibrate}>
                    <img src="/images/invite_friends_btn.png" alt="invite btn"/>
                    <InviteLinkText>
                        <p className='InviteLinkText1'>{t('task_invite_friends')}</p>
                        <p className='InviteLinkText2'>{t('task_earn_25_percent')}</p>
                        <p className='InviteLinkText2'>{t('task_from_friends_earnings')}</p>
                    </InviteLinkText>
                </LinkToInvite>
                <OfficialButtonContainer>
                    <OfficialButton onClick={openOfficialChat}>{t('official_chat')}</OfficialButton>
                    <OfficialButton onClick={openOfficialChannel}>{t('official_channel')}</OfficialButton>
                    <OfficialButton onClick={openSupport}>{t('support')}</OfficialButton>
                </OfficialButtonContainer>
                <TaskList>
                    {tasks.map((task, num) => (
                        <Task
                            key={num}
                            tasks={task.achievements}
                            onClick={() => handleTaskClick(task.achievements)}
                            onUpdate={updateTask}
                            today_completed={task.today_completed}
                        />
                    ))}
                </TaskList>
            </TasksPageContainer>
        )}
            <Footer/>

            {selectedTask && (
                <TaskModal tasks={selectedTask} onClose={closeModal}/>
            )}
        </>
    )
}


function Task({tasks, onClick, onUpdate, today_completed}) {
    const {t} = useTranslation();
    const {executeRecaptcha} = useGoogleReCaptcha();

    let task = tasks.find(task => !task.reward_received)
    if (!task) {
        task = tasks[tasks.length - 1]
        if (task.limit_value && today_completed < task.limit_value) {
            task.reward_received = false
            task.completed = false
            task.progress = 0
        }
    }

    const calmReward = async function () {
        try {
            const token = await executeRecaptcha('claim_reward');
            await axiosInstance.post('/achievements/claim-reward', {
                achievement_id: task.achievement_id,
                user_achievement_id: task.user_achievement_id
            }, {
                headers: {
                    'X-Recaptcha-Token': token
                }
            })
            confetti({
                particleCount: 150,
                spread: 70,
                origin: {y: 0.9},
                shapes: ['circle'],
                colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
            });
            const updatedTask = {...task, reward_received: true}
            onUpdate(updatedTask);
        } catch (e) {
            if (e.response && e.response.status === 403) {
                alert(t('suspected_bots'));
            } else {
                console.log(e);
            }
        }
    };
    return (
        <TaskContainer task={task} onClick={() => !task.completed && onClick()}>
            <ImgContainer task={task}>
                <img src="/images/task_icon.svg" alt="task icon"/>
            </ImgContainer>
            <p className="task-name">{task.name}</p>
            {!task.reward_received && task.completed ?
                <button onClick={calmReward}>{t('claim')} {task.reward}</button>
                : <div className="task-reward">{task.reward}</div>}

            {task.reward_received ? <IsDoneIMG src="/images/task_is_done.svg" alt="done"/> :
                <TaskProgress task={task}>
                    {task.progress}/{task.counter}
                </TaskProgress>
            }
            {task.limit_value &&
                <TaskLimit task={task}>
                    {t('completed_today')}: {today_completed}/{task.limit_value}
                </TaskLimit>
            }
        </TaskContainer>
    );
}

function TaskModal({tasks, onClose}) {
    const {t} = useTranslation();
    let task = tasks.find(task => !task.reward_received)
    if (!task) {
        task = tasks[tasks.length - 1]
        task.reward_received = false
        task.completed = false
        task.progress = 0
    }
    const handleClick = async () => {
        const {data: {id: userId}} = await axiosInstance.get('/user');

        let url = task.url;
        if (url.includes('{user_id}')) {
            url = url.replace('{user_id}', userId)
            console.log(url);
            window.Telegram.WebApp.openLink(url)
        } else {
            window.Telegram.WebApp.openTelegramLink(url);
        }
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
    }
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <ModalContent>
                    <TaskBrandLogo src="/images/task_brand_logo.svg" alt="close" className="close" onClick={onClose}/>
                    <div className="img-container">
                        <img src="/images/task_icon.svg" alt="task icon"/>
                    </div>
                    <h3>{task.name}</h3>

                    <p>{task.description}</p>
                    <div className="task-reward">{task.reward}</div>
                    {task.url &&
                        <button onClick={handleClick}>
                            {t('start_task')}
                        </button>
                    }
                </ModalContent>
            </ModalContainer>
        </ModalOverlay>
    );
}
