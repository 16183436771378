import styled from "styled-components";

export const LanguageSwitcherContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
`;

export const LanguageButton = styled.div`
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
`;

export const LanguageDropdown = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    padding: 5px 0;
    z-index: 1001;
`;

export const LanguageOption = styled.div`
    padding: 5px 10px;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #414141;
    }
`;